<template>

  <div class="chat-container">
    <div class="topchatpanel">
      <div class="topchatpanel-content">
        <div class="main-content-wrap">
          <div ref="chat_msg_wrap" class="chat-history">
            <!-- <p class="chat-message"></p> -->

            <div class="search_results" v-for="qa_item in search_results" :key="qa_item.id"
              :class="{ last_response: search_results.length == qa_item.id }">

              <div class="msg-wrap-user">
                <div class="msg-body-user">
                  <p class="chat-message your-message">
                    <span class="icon"><svg viewBox="0 0 24 24">
                        <path fill="currentColor"
                          d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
                      </svg></span>
                    <!-- {{ qa_item.id }} -  -->{{ qa_item.qs }}
                  </p>
                </div>
              </div>

              <div class="msg-wrap-aichat">
                <div class="msg-body-aichat">
                  <p class="chat-message ai-chat-message">
                    <span class="icon"><svg viewBox="0 0 640 512">
                        <path
                          d="M320 0c17.7 0 32 14.3 32 32V96H480c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H160c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256c0-22.1-17.9-40-40-40s-40 17.9-40 40s17.9 40 40 40s40-17.9 40-40zm152 40c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40s17.9 40 40 40zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z"
                          fill="currentColor" />
                      </svg>
                    </span>
                  <pre
                    v-if="qa_item.a.length"><span v-for="choice in qa_item.a" :key="choice.id" class="last_choice_text">{{ choice.text }}</span></pre>
                  <span v-if="is_typing" class="icon cursor-custom icon-cursor"></span>
                  <span v-if="is_typing_last && search_results.length == qa_item.id && !is_typing"
                    class="icon cursor-custom icon-cursor"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="landing-elements">
      <span class="icon-landing-center"><svg viewBox="0 0 640 512">
          <path
            d="M320 0c17.7 0 32 14.3 32 32V96H480c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H160c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256c0-22.1-17.9-40-40-40s-40 17.9-40 40s17.9 40 40 40s40-17.9 40-40zm152 40c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40s17.9 40 40 40zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z"
            fill="#A6A9BB" />

        </svg><i class="bordercursor3"></i><i class="bordercursor4"></i></span>

      <h1>AI Chatr</h1>
      <p>AI Chatr. Our goal is to make AI systems more natural and safe to interact with. Let's know if you have any questions</p>

    </div> -->
    <div class="topchatpanel-buttons">
      <!-- <div class="msg-wrap-user nopadbtm">
          <div class="msg-body-user">
            <div class="user-text-message w200">
              <button class="regenerate-stop-btn">
                <i><svg viewBox="0 0 24 24">
                    <path fill="currentColor"
                      d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
                  </svg></i>Regenerate Response</button>
            </div>
          </div>
        </div> -->


      <div class="msg-wrap-user nopadbtm">
        <form @submit.stop="getChoices()">
          <div class="msg-body-user">
            <div class="user-text-message">
              <input v-model="q" class="user-text-message-input" tabindex="1" type="text"
                placeholder="Please type your prompt here" />
              <span class="bordercursor2"></span>

              <button v-if="!loading" class="ssbtn" tabindex="2" @click="getChoices()">
                <svg viewBox="0 0 24 24">
                  <path fill="currentColor" d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
                </svg>
              </button>
              <button v-else class="ssbtn" tabindex="2">
                <svg class="dots-animation" width="120" height="30" viewBox="0 0 120 30"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <circle cx="15" cy="15" r="15">
                    <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15"
                      calcMode="linear" repeatCount="indefinite" />
                    <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1"
                      calcMode="linear" repeatCount="indefinite" />
                  </circle>
                  <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                    <animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear"
                      repeatCount="indefinite" />
                    <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5"
                      calcMode="linear" repeatCount="indefinite" />
                  </circle>
                  <circle cx="105" cy="15" r="15">
                    <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15"
                      calcMode="linear" repeatCount="indefinite" />
                    <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1"
                      calcMode="linear" repeatCount="indefinite" />
                  </circle>
                </svg>
              </button>

            </div>
            <div class="copyright-txt">Our goal is to make AI systems more natural and safe
              to interact with. Let's know if you have any questions.</div>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>
<script>

//import DefaultDashboard from "@/views/dashboards/Default.vue";
import { ref } from "vue";
import { nextTick } from 'vue'
import { mapMutations } from "vuex";

export default {
  name: "Home",
  components: {
    /* DefaultDashboard, */
  },
  setup() {
    let search_query = ref("q");
    let search_results = ref([]);
    const chat_msg_wrap = ref(null);
    const ref_key = ref(null);
    return { search_query, search_results, chat_msg_wrap, ref_key };
  },
  data() {
    return {
      choices: [],
      qa: [],
      loading: false,
      q: '',
      search_string: '',
      typed: false,
      is_typing: false,
      is_typing_last: false,
    };
  },
  computed: {
    /* filteredSearchResults() {
      return this.search_results.filter((sr) => {
        if (sr.id == this.search_results.length) {
          //this.displayTyping(sr);          
        }
        return sr;
      })
    } */
  },  
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  updated() {
    /* console.log("Updated!");
    var el = document.querySelector('.last_response .last_choice_text');
    if( document.body.contains(el) && !this.typed ){
      console.log("Not Typed")
      this.displayTyping();
      this.typed = true;
    } else {
      console.log("Already Typed!");
    } */

  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    getChoices: async function () {
      let uri = new URL(window.location.href)
      let params = new URLSearchParams(uri.search);
      if (params.get("k")) {

        document.querySelector(".topchatpanel-buttons").classList.add("userinput-questions");
        //document.querySelector(".landing-elements").classList.add("hide_landing_elements");

        this.is_typing_last = true;
        this.ref_key = params.get("k");
        console.log("Reference Key: " + this.ref_key);
        console.log("Ref: ", this.chat_msg_wrap);
        this.loading = true;
        this.typed = false;
        this.search_string = this.q;
        this.q = '';
        console.log("Sending Choices Request!")

        var before_search_length = this.search_results.length;
        var before_search_length_id = before_search_length + 1;
        var qa_obj = {};
        qa_obj.id = before_search_length_id;
        qa_obj.qs = this.search_string;
        qa_obj.a = [];
        this.search_results.push(qa_obj);

        var url = 'https://api.chatrpro.com/search?k=' + this.ref_key + '&q=' + this.search_string;
        console.log("Request URL: " + url);
        this.axios
          .get(url)
          .then(async (res) => {
            console.log("Array before: ", this.search_results);
            this.search_results.splice(-1)
            console.log("Array after: ", this.search_results);
            this.loading = false;
            this.choices = res?.data?.search_results?.choices;
            console.log("Choices:", this.choices)

            var search_length = this.search_results.length;
            var search_length_id = search_length + 1;

            var qa_obj = {};
            qa_obj.id = search_length_id;
            qa_obj.qs = this.search_string;
            qa_obj.a = res?.data?.search_results?.choices;

            if (qa_obj.a.length) {
              for (var k = 0; k < qa_obj.a.length; k++) {
                var selected_a = qa_obj.a[k];
                var selected_a_text = '';
                selected_a_text = selected_a?.message?.content;
                var selected_a_text_lines = selected_a_text.split('\n');

                if (!selected_a_text_lines[0].length) {
                  selected_a_text_lines.splice(0, 1);
                }

                if (!selected_a_text_lines[0].length) {
                  selected_a_text_lines.splice(0, 1);
                }

                if (selected_a_text_lines.length) {
                  selected_a_text = selected_a_text_lines.join('\n');
                  qa_obj.a[k].text = selected_a_text;
                }

              }
            }
            //this.qa.push(qa_obj);
            this.search_results.push(qa_obj);

            this.is_typing = true;
            await nextTick();
            await this.displayTyping();
            this.is_typing = false;
            this.is_typing_last = false;

            document.querySelector(".last_response .last_choice_text").classList.remove("lr_typing");
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    displayTyping: async function () {
      //this.is_typing = true;
      console.log("Display Typing for: ", this.chat_msg_wrap);
      this.chat_msg_wrap.classList.add("loading_chat_history");
      //console.log( "Search Result: ", sr );
      //var the_text = sr.a[0].text;
      var el = document.querySelector('.last_response .last_choice_text');
      if (document.body.contains(el)) {
        el.classList.add("lr_typing");
        const textArray = el.innerHTML.split(' ');
        el.innerHTML = '';
        textArray.forEach((letter, i) => {
          if (letter.length) {
            setTimeout(() => {
              el.innerHTML += letter
              el.innerHTML += " "
              //this.chat_msg_wrap.querySelector(".last_response .last_choice_text").scrollIntoView({ behavior: "smooth" });
              //this.chat_msg_wrap.querySelector(".last_response").scrollIntoView();
              this.chat_msg_wrap.querySelector(".last_response .last_choice_text").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            }, 150 * i)

          }
        });
        //el.classList.remove("lr_typing");

      }
    }
  },
};

</script>
